import { Directive, Input, OnInit } from '@angular/core';

import { BaseToggleDirective } from './base-toggle.directive';

@Directive({
    selector: '[availableFor]'
})
export class AvailableForDirective extends BaseToggleDirective implements OnInit {

  private readonly targetRoles: string[] = ['GlobalAdmin','User'];

  protected isElementNeeded(): boolean {
      return  this.currentUserStateService.hasOneOfRoles(this.targetRoles);
  }
}
