/**
 * to represent either chat is document or strucutred document chat
 */
export enum ChatType {
    UnstructuredDocument = "PDFDocument",
    StructuredDocument = "StructuredDocument",
    Normal = "normal",
    QEC = 'QEC',
    ESG = 'ESG',
    ESGStructuredDocs = 'ESGStructuredDocs',
    ESGUnstructuredDocs = 'ESESGUnstructuredDocs',
    StructuredUnstructuredDocs = 'StructuredUnstructuredDocs',
    ESGStructuredUnstructuredDocs = 'ESGStructuredUnstructuredDocs',
    codeInterpreter = 'codeInterpreter'
 }